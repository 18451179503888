import { gql } from '@apollo/client/core';
import { apollo } from '../service.apollo';
import {
  Adapter,
  mapAdapterFromBackend,
  AdapterBackend,
  nullAdapter,
} from './model';

export async function getAdapters(labId: string): Promise<Adapter[]> {
  const result = await apollo.query<{ adapters: AdapterBackend[] }>({
    variables: {
      labId,
    },
    query: gql`
      query GetAdapters($labId: ID!) {
        adapters(labId: $labId) {
          id
          scope
          banned
          killSupported
          activeSubscription
          info {
            adapterCommonVersion
            deploymentType
          }
          configuration {
            schemaVersion
            schemaTimestampISO
            schemaDocument {
              value
            }
            configValuesVersion
            configValuesTimestampISO
            configValuesDocument {
              value
            }
            resolved
          }
          healthMonitors {
            key
            value {
              state
              message
            }
          }
        }
      }
    `,
  });

  return result.data.adapters.map(mapAdapterFromBackend);
}

export async function getAdapter(
  labId: string,
  adapterId: string
): Promise<Adapter> {
  const result = await apollo.query<{ adapter: AdapterBackend }>({
    variables: {
      labId,
      adapterId,
    },
    query: gql`
      query GetAdapter($labId: ID!, $adapterId: ID!) {
        adapter(labId: $labId, adapterId: $adapterId) {
          id
          scope
          banned
          killSupported
          activeSubscription
          info {
            adapterCommonVersion
            deploymentType
          }
          configuration {
            schemaVersion
            schemaTimestampISO
            schemaDocument {
              value
            }
            configValuesVersion
            configValuesTimestampISO
            configValuesDocument {
              value
            }
            resolved
          }
          healthMonitors {
            key
            value {
              state
              message
            }
          }
        }
      }
    `,
  });

  return mapAdapterFromBackend(result.data.adapter);
}

/*
 * This function is used for polling the health of the adapters
 */
export async function getAdapterHealth(labId: string): Promise<Adapter[]> {
  const result = await apollo.query<{ adapters: AdapterBackend[] }>({
    variables: {
      labId,
    },
    context: { ignoreLongCalls: true },
    query: gql`
      query GetAdapterHealth($labId: ID!) {
        adapters(labId: $labId) {
          id
          activeSubscription
          healthMonitors {
            key
            value {
              state
              message
            }
          }
        }
      }
    `,
  });

  return result.data.adapters.map(mapAdapterFromBackend);
}

export async function setBannedConnection(
  adapterId: string,
  labId: string,
  banned: boolean
): Promise<boolean> {
  const response = await apollo.mutate<{ setBannedConnection: boolean }>({
    variables: {
      connectionId: adapterId,
      labId,
      banned,
    },
    mutation: gql`
      mutation SetBannedConnection(
        $connectionId: ID!
        $labId: ID!
        $banned: Boolean
      ) {
        setBannedConnection(
          connectionId: $connectionId
          labId: $labId
          banned: $banned
        )
      }
    `,
  });
  if (response.data?.setBannedConnection) {
    return response.data.setBannedConnection;
  }
  return false;
}

export async function killAdapter(scope: string): Promise<boolean> {
  const response = await apollo.mutate<{ killAdapter: boolean }>({
    variables: {
      scope,
    },
    mutation: gql`
      mutation KillAdapter($scope: String!) {
        killAdapter(scope: $scope)
      }
    `,
  });
  if (response.data?.killAdapter) {
    return response.data.killAdapter;
  }
  return false;
}

export async function removeAdapter(
  adapterId: string,
  labId: string
): Promise<string> {
  const response = await apollo.mutate<{ removeAdapter: string }>({
    variables: {
      adapterId,
      labId,
    },
    mutation: gql`
      mutation RemoveAdapter($adapterId: ID!, $labId: ID!) {
        removeAdapter(adapterId: $adapterId, labId: $labId)
      }
    `,
  });
  return response.data?.removeAdapter || '';
}

export async function addAdapter(
  labId: string,
  name: string,
  image: string
): Promise<Adapter> {
  const response = await apollo.mutate<{ addAdapter: AdapterBackend }>({
    variables: {
      labId,
      name,
      image,
    },
    mutation: gql`
      mutation AddAdapter($labId: ID!, $name: String!, $image: String!) {
        addAdapter(labId: $labId, name: $name, image: $image) {
          id
          name
          scope
        }
      }
    `,
  });
  if (response.data?.addAdapter) {
    const adapter = mapAdapterFromBackend(response.data.addAdapter);
    adapter.id = name;
    adapter.name = name;
    adapter.labId = labId;
    return adapter;
  }
  return { ...nullAdapter(), id: name, name, labId };
}
