export interface Common {
  revision: string;
  createdTimestamp: string;
  modifiedTimestamp: string;
  createdBy: string;
  displayOwnerName: string;
}

export function nullCommon(): Common {
  return {
    revision: '',
    createdTimestamp: '',
    modifiedTimestamp: '',
    createdBy: '',
    displayOwnerName: '',
  };
}

export interface ScheduleTime {
  startTimestamp: string;
  endTimestamp: string;
}

export interface Timelog {
  estimate: ScheduleTime | null;
  actual: ScheduleTime | null;
}

export enum SimplifiedState {
  UNKNOWN = 'UNKNOWN',
  CREATED = 'CREATED',
  INITIALIZED = 'INITIALIZED',
  STARTING = 'STARTING',
  RUNNING_WITH_ASSISTANCE = 'RUNNING_WITH_ASSISTANCE',
  RUNNING_NEED_ASSISTANCE = 'RUNNING_NEED_ASSISTANCE',
  RUNNING_WITH_ERROR = 'RUNNING_WITH_ERROR',
  RUNNING = 'RUNNING',
  PAUSING = 'PAUSING',
  PAUSED = 'PAUSED',
  RESUMING = 'RESUMING',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  STOPPING = 'STOPPING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  ON_HOLD = 'ON_HOLD',
  BATCHED = 'BATCHED',
  UNSCHEDULED = 'UNSCHEDULED',
  ACTIVE = 'ACTIVE', // no BE equivalent - used to aggregate states that user's will care about
}

export const RUNNING_STATES = [
  SimplifiedState.RUNNING,
  SimplifiedState.RUNNING_WITH_ASSISTANCE,
  SimplifiedState.PAUSING,
];

export const ACTIVE_STATES = [
  SimplifiedState.RUNNING,
  SimplifiedState.RUNNING_NEED_ASSISTANCE,
  SimplifiedState.RUNNING_WITH_ASSISTANCE,
  SimplifiedState.RUNNING_WITH_ERROR,
  SimplifiedState.ON_HOLD,
  SimplifiedState.PAUSING,
  SimplifiedState.PAUSED,
  SimplifiedState.RESUMING,
];

export const PAUSED_STATES = [
  SimplifiedState.PAUSED,
  SimplifiedState.ON_HOLD,
  SimplifiedState.RESUMING,
];

export function nullTimelog(): Timelog {
  return {
    estimate: { startTimestamp: '', endTimestamp: '' },
    actual: { startTimestamp: '', endTimestamp: '' },
  };
}

export enum IoType {
  SAMPLE = 'SAMPLE',
  PLATE = 'PLATE',
  MICRO_PLATE = 'MICRO_PLATE',
  DEEP_WELL_PLATE = 'DEEP_WELL_PLATE',
  TUBE = 'TUBE',
  TIP = 'TIP',
  SEAL = 'SEAL',
  BEAD = 'BEAD',
  REAGENT = 'REAGENT',
  OTHER_IO = 'OTHER_IO',
}

export enum Unit {
  COUNT = 'COUNT',
  MICROLITER = 'MICROLITER',
  MILLILITER = 'MILLILITER',
  LITER = 'LITER',
}

export interface Quantity {
  unit: Unit;
  value: number;
}

export interface Sample {
  id: string;
  labId: string;
  name: string;
  description: string;
  type: string;
  quantity: Quantity;
  metadata: string;
  common: Common;
}

export interface Asset {
  id: string;
  labId: string;
  definitionId: string;
  parentId: string;
  parentSocketIdx?: number;
  color?: string;
  name: string;
  location?: Location;
  common: {
    revision: string;
  };
  // children: Asset[];
}

export interface ActionMetadata {
  blob: string;
}

export interface UserField {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export interface IO {
  id: string;
  name: string;
  type: IoType;
  samples: Sample[];
  assets: Asset[];
  referenceIdsList: string[];
  blob?: string;
  actualquantity: Quantity;
  basequantity: Quantity;
  metadatas?: ActionMetadata[];
  userFields?: UserField[];
}

export function nullIO(): IO {
  return {
    id: '',
    name: '',
    type: IoType.OTHER_IO,
    referenceIdsList: [],
    blob: '',
    samples: [],
    assets: [],
    actualquantity: {
      value: 0,
      unit: Unit.COUNT,
    },
    basequantity: {
      value: 0,
      unit: Unit.COUNT,
    },
    metadatas: [],
    userFields: [],
  };
}

export interface DefinedFrom {
  id: string;
  rev: string;
}

export function nullDefinedFrom() {
  return { id: '', rev: '' };
}

export const DashboardFeatureFlags = [
  'DOCUMENT_WORKFLOW',
  'USE_TECAN_BRANDING',
  'SIGNOFF_GROUPS',
  'COMPLIANCE_MODE',
  'ADD_REMOVE_ADAPTER',
  'FUTURE_GANTT',
] as const;

export type DashboardFeatureFlag = (typeof DashboardFeatureFlags)[number];
