
import { defineComponent, computed, ref, watch, Ref, PropType } from 'vue';
import OpsController, { CommandState, JobFilters, Job } from '@/clients/ops';
import UIController from '@/clients/ui';
import UserController from '@/clients/users';
import { SimplifiedState } from '@/clients/model';
import JobsTable, { JobsTableMode } from '@/components/JobsTable/JobsTable.vue';
import JobsTableFilters from '@/components/JobsTable/JobsTableFilters.vue';
import FiltersController from '@/components/JobsTable/filtersController';
import { Columns, ColumnLabels } from '@/components/JobsTable/model';
import CheckSquare from '@/components/CheckSquare.vue';
import PlanningTableSettings from './components/Planning/PlanningTableSettings.vue';
import RetryActionDialog, {
  RetryActionData,
} from './components/JobCards/RetryActionDialog.vue';
import SkipActionDialog from './components/JobCards/SkipActionDialog.vue';
import ActionDataEntryDialog from '@/components/ActionDataEntryDialog.vue';
import CreateMenu from './components/CreateMenu.vue';
import JobActions from './components/Planning/JobActions.vue';
import JobMetrics from './components/JobMetrics.vue';
import FullPageDialog from '@/components/FullPageDialog.vue';
import ConfirmCancelDialog from '@/components/ConfirmCancelDialog.vue';
import DrawerTemplate from '@/components/Drawers/DrawerTemplate.vue';
import {
  isJobPausable,
  isJobResumable,
  isJobDeleteable,
  isJobUnschedulable,
  isJobCancelable,
} from '@/pages/utils';
import {
  JobSetChangeMessagePayload,
  JobSetChangeType,
  JobStateChangeMessagePayload,
} from '@/clients/ops/messages';
import { Action, nullAction } from '@/clients/action/model';
import { debounce } from 'lodash';

export default defineComponent({
  components: {
    JobsTable,
    JobsTableFilters,
    CheckSquare,
    PlanningTableSettings,
    RetryActionDialog,
    SkipActionDialog,
    ActionDataEntryDialog,
    CreateMenu,
    JobActions,
    JobMetrics,
    FullPageDialog,
    ConfirmCancelDialog,
    DrawerTemplate,
  },
  props: {
    labId: {
      type: String,
      required: true,
    },
    filters: {
      type: Object as PropType<JobFilters>,
      required: true,
    },
    mode: {
      type: String,
      default: JobsTableMode.FULL,
    },
  },
  emits: ['open-logs', 'select-job', 'create-job'],
  setup(props, { emit }) {
    /**
     * BASIC TABLE PROPS
     */
    const fullTableView = ref(props.mode === JobsTableMode.FULL);
    const tableHeight = computed(() => {
      if (fullTableView.value) {
        return { height: 'calc(100vh - 100px)' };
      } else {
        return { height: 'calc(100vh - 300px)' };
      }
    });

    const visibleColumns = [
      Columns.SELECT,
      Columns.NAME,
      Columns.STATE,
      Columns.ACTION,
      Columns.CREATED,
      Columns.CREATED_BY,
      Columns.WORKFLOW,
      Columns.START,
      Columns.CONFIG,
    ];

    const allowedColumns = [
      Columns.NAME,
      Columns.STATE,
      Columns.ACTION,
      Columns.CREATED,
      Columns.CREATED_BY,
      Columns.WORKFLOW,
      Columns.START,
      Columns.START_ESTIMATE,
      Columns.END,
      Columns.END_ESTIMATE,
      Columns.MODIFIED,
    ];

    const orderBy = ['desc', 'state', 'desc', 'createdTimestamp'];
    let saveContext =
      props.mode === JobsTableMode.FULL
        ? `planning-full_${props.labId}`
        : `planning-mini_${props.labId}`;

    // this "local" controller coordinates all the settings of the table by aggregating it as reactive data
    const filterController = new FiltersController(
      saveContext,
      props.filters,
      orderBy,
      visibleColumns
    );

    const nameFilter = ref('');
    const updateNameFilter = debounce(() => {
      if (nameFilter.value) {
        filterController.nameFilters = [nameFilter.value];
      } else {
        filterController.nameFilters = undefined;
      }
    }, 500);

    const numFilters = computed(() => filterController.numSetFilters);

    const expandedFiltersVisible = ref(false);
    const showExpandedFilters = () => {
      expandedFiltersVisible.value = true;
      UIController.Instance.displayLeftDrawer = true;
    };
    const closeExpandedFilters = () => {
      expandedFiltersVisible.value = false;
      UIController.Instance.displayLeftDrawer = false;
    };

    const onJobSetChanged = (
      setChange: JobSetChangeMessagePayload
    ): boolean => {
      let needsRequery = false;
      if (filterController.autoUpdateTable) {
        let i = 0;
        while (!needsRequery && i < setChange.jobs.length) {
          const j = setChange.jobs[i];
          if (
            j.type === JobSetChangeType.DELETE &&
            OpsController.Instance.currentJobsPage.find((mj) => mj.id === j.id)
          ) {
            // if a job that we're currently showing in the table got deleted, we need a refresh
            needsRequery = true;
          } else if (
            j.type === JobSetChangeType.ADD &&
            (!filterController.stateFilter?.length ||
              filterController.stateFilter.includes(SimplifiedState.CREATED))
          ) {
            // if we're tracking jobs of state CREATED or ALL, and a new job just got added, we need a refresh
            needsRequery = true;
          }
          i += 1;
        }
      }
      return needsRequery;
    };

    const onJobStateChanged = (
      jobStates: JobStateChangeMessagePayload
    ): boolean => {
      let needsRequery = false;
      if (filterController.autoUpdateTable) {
        let i = 0;
        while (!needsRequery && i < jobStates.jobs.length) {
          const j = jobStates.jobs[i];
          const currentlyShowingJob =
            OpsController.Instance.currentJobsPage.find((mj) => mj.id === j.id);
          // if we're showing the job and it actually changed state, we have to requery
          // otherwise, if the new state is something we're filtering out, we don't need to requery
          if (
            !filterController.stateFilter?.length ||
            currentlyShowingJob ||
            filterController.stateFilter?.includes(j.oldState) ||
            filterController.stateFilter?.includes(j.newState)
          ) {
            needsRequery = true;
          }
          i += 1;
        }
      }
      return needsRequery;
    };

    const focusedAction = (jobId: string): Action | null => {
      return OpsController.Instance.getFocusedAction(jobId) || null;
    };

    const currentlyErroredAction = (jobId: string): Action | null => {
      const failedActionId =
        OpsController.Instance.getLastFailedAction(jobId)?.actionId;
      if (failedActionId) {
        return OpsController.Instance.getAction(failedActionId);
      }
      return null;
    };

    const openLogs = (job: Job) => {
      emit('open-logs', job.name);
    };

    /**
     * RETRY ACTIONS IN ERROR
     */
    const retryActionDialog = ref(false);
    const retryActionData: Ref<RetryActionData | null> = ref(null);
    const openRetryDialog = (job: Job) => {
      retryActionData.value = {
        job,
        action: currentlyErroredAction(job.id),
        labId: props.labId,
        error: '',
      };
      retryActionDialog.value = true;
    };
    const closeRetryDialog = () => {
      retryActionData.value = null;
      retryActionDialog.value = false;
    };

    const skipActionDialog = ref(false);
    const actionToSkip: Ref<Action> = ref(nullAction());
    const openSkipDialog = (action: Action) => {
      actionToSkip.value = action;
      skipActionDialog.value = true;
    };

    const dataEntryDialog = ref(false);
    const promptAction: Ref<Action> = ref(nullAction());
    const openUserPromptDialog = (action: Action) => {
      promptAction.value = action;
      dataEntryDialog.value = true;
    };
    const skipUserPrompt = (action: Action) => {
      OpsController.Instance.dispatchFinishDialogAction(
        action.id,
        UserController.Instance.currentUser.id,
        new Date().toISOString()
      );
      dataEntryDialog.value = false;
    };

    const selectedJobs: Ref<string[]> = ref([]);
    const setSelectedJobs = (jobIds: string[]) => {
      selectedJobs.value.splice(0, selectedJobs.value.length, ...jobIds);
    };

    const openJobDetailsPanel = (jobId: string) => {
      const job = OpsController.Instance.getJob(jobId);
      if (job.id) {
        emit('select-job', job);
      }
    };

    const handleBatchedJobsCommand = async (command: CommandState) => {
      return await OpsController.Instance.dispatchBatchedJobCommand(
        selectedJobs.value,
        command
      );
    };

    const handleDeleteSelectedJobs = async () => {
      await handleBatchedJobsCommand(CommandState.DELETE);
      selectedJobs.value = [];
    };

    const handlePauseSelectedJobs = async () => {
      await handleBatchedJobsCommand(CommandState.PAUSE);
    };

    const handleResumeSelectedJobs = async () => {
      await handleBatchedJobsCommand(CommandState.RESUME);
    };
    const handleCancelSelectedJobs = async () => {
      await handleBatchedJobsCommand(CommandState.CANCEL);
    };
    const handleUnscheduleSelectedJobs = async () => {
      await handleBatchedJobsCommand(CommandState.UNSCHEDULE);
    };

    const allSelectedJobsAreDeletable = computed(() =>
      OpsController.Instance.currentJobsPage
        .filter((j) => selectedJobs.value.includes(j.id))
        .every((j) => isJobDeleteable(j.state))
    );

    const allSelectedJobsArePausable = computed(() =>
      OpsController.Instance.currentJobsPage
        .filter((j) => selectedJobs.value.includes(j.id))
        .every((j) => isJobPausable(j.state))
    );

    const allSelectedJobsAreResumable = computed(() =>
      OpsController.Instance.currentJobsPage
        .filter((j) => selectedJobs.value.includes(j.id))
        .every((j) => isJobResumable(j.state))
    );

    const allSelectedJobsAreCancelable = computed(() =>
      OpsController.Instance.currentJobsPage
        .filter((j) => selectedJobs.value.includes(j.id))
        .every((j) => isJobCancelable(j.state))
    );

    const allSelectedJobsAreUnschedulable = computed(() =>
      OpsController.Instance.currentJobsPage
        .filter((j) => selectedJobs.value.includes(j.id))
        .every((j) => isJobUnschedulable(j.state))
    );

    watch(
      () => props.labId,
      (newId, oldId) => {
        if (newId !== oldId) {
          saveContext =
            props.mode === JobsTableMode.FULL
              ? `planning-full_${newId}`
              : `planning-mini_${newId}`;
          filterController.switchLab(newId, saveContext);
        }
      }
    );
    watch(
      () => props.filters,
      () => {
        if (props.filters.states && props.filters.states.length > 0) {
          // pass our filters down to the filter controller
          filterController.stateFilter = props.filters.states;
          // and reset our offset
          filterController.offset = 0;
        }
      },
      { deep: true, immediate: true }
    );
    watch(
      () => filterController.nameFilters,
      () => {
        if (
          (filterController.nameFilters &&
            filterController.nameFilters.length !== 1) ||
          nameFilter.value !== filterController.nameFilters?.[0]
        ) {
          nameFilter.value = '';
        }
      },
      { deep: true }
    );
    const showConfirmCancelDialog = ref(false);

    return {
      fullTableView,
      filterController,
      tableHeight,
      numFilters,
      nameFilter,
      updateNameFilter,
      expandedFiltersVisible,
      showExpandedFilters,
      closeExpandedFilters,
      Columns,
      allowedColumns,
      saveContext,
      onJobSetChanged,
      onJobStateChanged,
      focusedAction,
      openLogs,
      retryActionDialog,
      retryActionData,
      openSkipDialog,
      skipActionDialog,
      actionToSkip,
      openUserPromptDialog,
      skipUserPrompt,
      dataEntryDialog,
      promptAction,
      openRetryDialog,
      closeRetryDialog,
      selectedJobs,
      openJobDetailsPanel,
      setSelectedJobs,
      handleDeleteSelectedJobs,
      handlePauseSelectedJobs,
      handleResumeSelectedJobs,
      handleCancelSelectedJobs,
      handleUnscheduleSelectedJobs,
      allSelectedJobsArePausable,
      allSelectedJobsAreResumable,
      allSelectedJobsAreCancelable,
      allSelectedJobsAreDeletable,
      allSelectedJobsAreUnschedulable,
      showConfirmCancelDialog,
      ColumnLabels,
    };
  },
});
