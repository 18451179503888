import { morphism, createSchema } from 'morphism';
import { EntityConfiguration } from '../config';
import {
  AdapterConfigBackend,
  customCloneFrom,
  EntityType,
} from '../config/model';
import { cloneDeepWith } from 'lodash';

export interface AdapterState {
  connected: boolean;
  connecting?: boolean;
  removing?: boolean;
  configured: boolean;
  disabled: boolean;
}

export enum HealthState {
  UNKNOWN = 'UNKNOWN',
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface HealthMessage {
  message: string;
  state: HealthState;
}

export interface HealthMonitors {
  [key: string]: HealthMessage;
}

interface HealthMonitorsBackend {
  key: string;
  value: HealthMessage;
}

export enum AdapterDeploymentType {
  ADAPTER_DEPLOYMENT_TYPE_UNKNOWN = 'ADAPTER_DEPLOYMENT_TYPE_UNKNOWN',
  ADAPTER_DEPLOYMENT_TYPE_LOCAL = 'ADAPTER_DEPLOYMENT_TYPE_LOCAL',
  ADAPTER_DEPLOYMENT_TYPE_CLOUD = 'ADAPTER_DEPLOYMENT_TYPE_CLOUD',
  ADAPTER_DEPLOYMENT_TYPE_CODESPACE = 'ADAPTER_DEPLOYMENT_TYPE_CODESPACE',
  ADAPTER_DEPLOYMENT_TYPE_IPC = 'ADAPTER_DEPLOYMENT_TYPE_IPC',
}

export interface AdapterInfo {
  adapterCommonVersion: string;
  deploymentType: AdapterDeploymentType;
}

export interface AdapterBackend {
  id: string;
  name: string;
  labId: string;
  version: string;
  scope: string;
  killSupported: boolean;
  activeSubscription: boolean;
  banned: boolean;
  info: AdapterInfo;
  healthMonitors: HealthMonitorsBackend[];
  configuration: AdapterConfigBackend;
}

export interface Adapter {
  id: string;
  name: string;
  labId: string;
  config?: EntityConfiguration;
  version: string;
  scope?: string;
  killSupported?: boolean;
  killEnabled?: boolean;
  activeSubscription?: boolean;
  banned?: boolean;
  info: AdapterInfo;
  healthMonitors: HealthMonitors;
  health?: AdapterHealth; // deprecated
  numInstances: number; // deprecated
  state: AdapterState; // deprecated
}

export interface AdapterHealth {
  artificialConnectionHealth: HealthState;
  clientHealthStatus?: AdapterHealthState;
  aggregateHealth: AdapterHealthState;
}

export enum AdapterHealthState {
  ALL_CONNECTIONS_HEALTHY = 'ALL_CONNECTIONS_HEALTHY',
  SOME_CONNECTIONS_HEALTHY = 'SOME_CONNECTIONS_HEALTHY',
  NO_CONNECTIONS_HEALTHY = 'NO_CONNECTIONS_HEALTHY',
  NO_ADAPTORS = 'NO_ADAPTORS_CONNECTED',
  UNKNOWN = 'UNKNOWN',
}

export function nullAdapter(): Adapter {
  return {
    id: '',
    name: '',
    labId: '',
    version: '',
    info: {
      adapterCommonVersion: '',
      deploymentType: AdapterDeploymentType.ADAPTER_DEPLOYMENT_TYPE_UNKNOWN,
    },
    healthMonitors: {},
    numInstances: 1,
    state: {
      connected: false,
      configured: false,
      disabled: false,
      connecting: false,
      removing: false,
    },
  };
}

const mapConfigFromBackendSchema = createSchema<
  EntityConfiguration,
  AdapterConfigBackend
>({
  configValuesTimestamp: (input) => new Date(input.configValuesTimestampISO),
  configValuesVersion: 'configValuesVersion',
  configValues: (input) => {
    try {
      const values = JSON.parse(input.configValuesDocument.value);
      // this will escape relevent characters for display purposes
      return cloneDeepWith(values, customCloneFrom);
    } catch (_) {
      console.warn('invalid configValuesDocument', input.configValuesDocument);
      return {};
    }
  },
  entityId: 'entityId', // the ConfigValuesEnvelope doesn't have entityId labId or type for some reason
  entityType: () => EntityType.ENTITY_TYPE_ADAPTER,
  labId: 'labId',
  orgId: 'orgId',
  resolved: 'resolved',
  schemaTimestamp: (input) => new Date(input.schemaTimestampISO),
  schemaVersion: 'schemaVersion',
  schema: (input) => {
    try {
      const s = JSON.parse(input.schemaDocument.value);
      s.$schema = 'http://json-schema.org/draft-07/schema#';
      return s;
    } catch (_) {
      console.warn('invalid config schema', input.schemaDocument);
      return {};
    }
  },
});

function mapConfigFromBackend(
  backend: AdapterConfigBackend
): EntityConfiguration {
  return morphism(mapConfigFromBackendSchema, backend);
}

const mapAdapterFromBackendSchema = createSchema<Adapter, AdapterBackend>({
  id: 'id',
  name: (input) => input.name || input.id,
  labId: 'labId',
  version: 'version',
  scope: 'scope',
  killSupported: 'killSupported',
  activeSubscription: 'activeSubscription',
  banned: 'banned',
  info: (input) => {
    if (input.info) {
      return input.info;
    }
    return {
      adapterCommonVersion: '',
      deploymentType: AdapterDeploymentType.ADAPTER_DEPLOYMENT_TYPE_UNKNOWN,
    };
  },
  healthMonitors: (input) => {
    const monitors: HealthMonitors = {};
    input.healthMonitors.forEach((monitor) => {
      monitors[monitor.key] = monitor.value;
    });
    return monitors;
  },
  config: (input) => {
    const mappedConfig = mapConfigFromBackend(input.configuration);
    if (mappedConfig) {
      // we don't have context to set these in the config mapper above, so we do it here
      mappedConfig.entityId = input.id;
      mappedConfig.labId = input.labId;
      return mappedConfig;
    }
  },
  numInstances: 1,
  state: (input) => {
    return {
      connected: input.activeSubscription,
      disabled: input.banned,
      configured: !!input.configuration && input.configuration.resolved,
    };
  },
});

export function mapAdapterFromBackend(backend: AdapterBackend): Adapter {
  return morphism(mapAdapterFromBackendSchema, backend);
}
