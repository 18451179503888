import { EventDispatcher } from 'three';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import OpsController from '@/clients/ops/controller';
import {
  GanttDataFilters,
  GanttBlockEvent,
  GanttData,
  GanttPointEvent,
  EventDetailsD3Type,
  GanttLane,
  encodeTimestamp,
  decodeTimestamp,
} from './ganttModel';
import { GanttRequest, GanttRescheduleActivityType } from '@/clients/ops/model';
import ganttDataSanity from './ganttDataSanity';
import { JobEventType } from '@/clients/ops';
import ActionController from '@/clients/action';
import getFakeGanttData from '@/pages/Ops/components/JobCreation/fakeGanttDataGen';

/**
 * GOALS/TODO
 * Could/should make the data readonly (at least most fields) so users don't accidentally change it
 * Find a better place for the data conversion - where to draw the line for data conversion provided here?
 * Need to re-add mechanism to get more data when you scroll around?  it still has issues
 * Should the visible view area of the gantt live here?  in another controller?  it is/could be related to the data window but I'm looking for ways to not put everything in this file
 * xstate? currently have a mock up for the data update state control - use more?
 *
 *  */

interface GanttDataEventMap {
  UPDATE: { newValue: GanttData; oldValue: GanttData | undefined };
  DISPOSE;
}

/**
 * Centralized store for the gantt data in a gantt chart
 */
export default class GanttController extends EventDispatcher<GanttDataEventMap> {
  private _dataFilters: GanttDataFilters;
  private _ganttData: GanttData | undefined = undefined;
  private _flatBoxList: GanttBlockEvent[] = [];
  /** a promise that resolves once the controller is ready to use */
  private _initializationComplete: Promise<boolean>;
  /** a simple way to toggle data updates on and off when desired */
  private _suspendDataUpdates = false;

  /**
   * Create a new controller. You must create a new controller to go along with every gantt chart
   * @param labId ID of the lab we are viewing gantt data for
   * @param includeProjections should this include schedule projection data too
   * @param dataFilters initial view filters for the data
   */
  constructor(
    private readonly _labId: string,
    private readonly _includeProjections: boolean,
    dataFilters: GanttDataFilters,
    private readonly _fakeDataCount: number
  ) {
    super();

    // setup initial data for gantt controller
    this._dataFilters = cloneDeep(dataFilters);
    this._initializationComplete = this.initGanttController();

    console.log(
      `GanttController started for lab: ${this.labId} with includeProjections: ${this.includeProjections}.  `,
      this._dataFilters
    );
  }

  dispose() {
    console.log('disposing ganttController');
    // how should this work?  notifications to things that are asking for timed updates?
    this.dispatchEvent({ type: 'DISPOSE' });
  }

  private async initGanttController() {
    await this.updateGanttData(this.dataFilters);
    return true;
  }

  /**
   *
   * @param dataFilters add comment here
   * @returns promise that resolves to true if the update happened successfully
   */
  async updateGanttData(dataFilters?: GanttDataFilters): Promise<boolean> {
    // crude way to disable data updates
    if (this.suspendDataUpdates) {
      return false;
    }

    // optionally update the filter
    if (dataFilters) {
      this._dataFilters = cloneDeep(dataFilters);
    }

    // request the data
    const ganttRequest =
      this._fakeDataCount > 0
        ? await getFakeGanttData(this._fakeDataCount)
        : await OpsController.Instance.dispatchGetGanttJobsWithProjections(
            this.labId,
            encodeTimestamp(this.dataFilters.completedAfter),
            encodeTimestamp(this.dataFilters.startedBefore),
            this.includeProjections,
            this.dataFilters.limit,
            this.dataFilters.offset
          );

    // do sanity checking
    ganttDataSanity(ganttRequest);

    // convert the data
    try {
      const oldValue = this._ganttData;
      const newValue = OldGanttConversions.initGanttData(
        ganttRequest,
        this.labId
      );

      // fire update event here
      this._ganttData = newValue;
      this.dispatchEvent({ type: 'UPDATE', newValue, oldValue });
    } catch (error) {
      console.error('Exception when processing gantt chart data.');
      return false;
    }
    return true;
  }

  /**
   * Sends an activity reschedule to the back end
   * @param jobId
   * @param activityId
   * @param start
   * @param end
   * @returns true if successful
   */
  async sendActivityReschedule(
    jobId: string,
    activityId: string,
    start: DateTime,
    end: DateTime
  ): Promise<boolean> {
    // could only send newStart and newDuration if they've changed
    const rescheduleData: GanttRescheduleActivityType = {
      labId: this.labId,
      jobId,
      activityId,
      newStart: encodeTimestamp(start),
      newDuration: end.diff(start, 'seconds').seconds,
    };
    // console.log(rescheduleData);
    return OpsController.Instance.dispatchGanttRescheduleActivity(
      rescheduleData
    );
  }

  /**
   * ACCESSORS
   */

  public get isInitComplete(): Promise<boolean> {
    return this._initializationComplete;
  }

  public get labId(): string {
    return this._labId;
  }

  public get includeProjections(): boolean {
    return this._includeProjections;
  }

  public get suspendDataUpdates(): boolean {
    return this._suspendDataUpdates;
  }
  public set suspendDataUpdates(suspend: boolean) {
    this._suspendDataUpdates = suspend;
  }

  public get dataFilters(): GanttDataFilters {
    return this._dataFilters;
  }

  public get ganttData(): GanttData {
    if (!this._ganttData) {
      throw new Error('Empty gantt data in gantt controller.');
    }
    return this._ganttData;
  }

  public get flatBoxList(): GanttBlockEvent[] {
    return this._flatBoxList;
  }
}

class OldGanttConversions {
  private static mapLaneEventColors = (state: string | null): string => {
    if (state === null) {
      return 'green';
    }
    // do the color mapping
    const colorMap = {
      ACTIVITY_TYPE_ACTION_RUNNING: '#0B83FF',
      ACTIVITY_TYPE_ASSISTANT_RUNNING: '#0B83FF',
      ACTIVITY_TYPE_ASSISTANCE_NEEDED: '#F2C94C',
      ACTIVITY_TYPE_ERROR: '#E02139',
      ACTIVITY_TYPE_PAUSING: '#054180',
      ACTIVITY_TYPE_PAUSED: '#054180',
      ACTIVITY_TYPE_UNKNOWN: '#7B7B7B',
      ACTIVITY_TYPE_ON_HOLD: '#6061D4',
      ACTIVITY_TYPE_SCHEDULED_ACTION: '#0B83FF',
      ACTIVITY_TYPE_SCHEDULED_ASSISTANT: '#F2C94C',
    };
    const color = colorMap[state] || 'black';
    if (color === 'black') {
      console.warn(`unknown state:  ${state}`);
    }
    return color;
  };

  static initGanttData(ganttRequest: GanttRequest, labId: string): GanttData {
    // gantt expects ids for lanes to be globally unique, let's verify this
    // move this to sanity checker...
    const laneIdSet = new Set<string>();
    let pointEventId = 0; // Michael is eventually adding the real IDs to the annotation data
    return {
      labId,
      snapshotTime: decodeTimestamp(ganttRequest.projectionsComputedAt),
      lanes: ganttRequest.jobs
        .filter((data) => data.activities.length > 0)
        .map((lane) => {
          if (laneIdSet.has(lane.id)) {
            console.error(`Duplicate lane id:  ${lane.id}`);
          }
          laneIdSet.add(lane.id);
          const action = ActionController.Instance.getAction(lane.workflowId);
          const blockEvents = lane.activities
            // this line filters out events we want to not show
            // I was asked to remove it, but then told we might bring it back...
            // .filter((data) => data.state !== 'PAUSED')
            .map((activity) => {
              return OldGanttConversions.createBlockEvent(
                activity.id,
                activity.name,
                activity.start,
                activity.end,
                activity.state,
                activity.projected,
                lane.name,
                action.name,
                activity.resourceId
              );
            });

          const pointEvents = lane.annotations.map((ev) => {
            if (ev.timestamp === '') {
              console.warn('timestamp is empty');
            }
            const temp: GanttPointEvent = {
              type: ev.type,
              time: decodeTimestamp(ev.timestamp),
              id: `pointEvent${pointEventId++}`, // hacked for now, I think we could do away with this and also stop using it on the d3 side
            };
            return temp;
          });

          return OldGanttConversions.createLane(
            lane.id,
            lane.name,
            blockEvents,
            lane.start,
            lane.end,
            pointEvents
          );
        }),
    };
  }

  static createBlockEvent(
    id: string,
    name: string,
    startTimestamp: string,
    endTimestamp: string,
    state: string | null,
    projected: boolean,
    parentJobName: string,
    workflowName: string,
    resourceId?: string
    // color = 'blue'
  ): GanttBlockEvent {
    const start = decodeTimestamp(startTimestamp);
    const end = decodeTimestamp(endTimestamp);
    const color = OldGanttConversions.mapLaneEventColors(state);
    const blockName =
      state === 'ACTIVITY_TYPE_ASSISTANCE_NEEDED'
        ? `Assistance needed with ${name}`
        : name;

    const eventDetails: EventDetailsD3Type = {
      start,
      end,
      titleString: blockName,
      contentString1: parentJobName,
      contentIcon1: 'NoIcon',
      contentString2: workflowName,
      contentIcon2: 'WorkflowIcon',
    };
    return {
      id,
      name: blockName,
      start,
      end,
      color,
      drawProjected: projected,
      locked: false,
      noCheckmark: true, // turn off the check mark
      popupDetails: eventDetails,
      resourceId,
    };
  }

  static createLane(
    id: string,
    name: string,
    blockEvents: GanttBlockEvent[],
    startTimestamp: string,
    endTimestamp: string,
    pointEvents: GanttPointEvent[]
  ): GanttLane {
    // hack to remove "File Uploaded" events and recast "File Uploading" events
    const pointEvents2 = pointEvents
      .filter((ev) => {
        // temporary hack to remove "File Uploaded events"
        return ev.type !== JobEventType.JOB_FILE_CAPTURED;
      })
      .map((ev) => {
        // temporary hack to convert "File Uploading" events into "File Uploaded" instead
        const eventType =
          ev.type === JobEventType.ACTION_FILE_CAPTURE_INITIATED
            ? JobEventType.JOB_FILE_CAPTURED
            : ev.type;
        return {
          ...ev,
          type: eventType,
        };
      });
    // end hack

    const drawEndCircle = endTimestamp !== '';
    const lastActivityEnd = blockEvents[blockEvents.length - 1].end;
    const end = drawEndCircle ? decodeTimestamp(endTimestamp) : lastActivityEnd;
    const emptyLane: GanttLane = {
      id: id,
      name: name,
      blockEvents,
      pointEvents: pointEvents2,
      start:
        startTimestamp === ''
          ? blockEvents[0].start
          : decodeTimestamp(startTimestamp),
      end,
      drawEndCircle,
    };
    return emptyLane;
  }
}
