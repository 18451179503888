
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  Ref,
  PropType,
} from 'vue';
import { OutputUnit } from '@hyperjump/json-schema/draft-07';
import { isEqual, isString } from 'lodash';
import OpsController from '@/clients/ops';
import SimpleArrayInput from '@/components/SimpleArrayInput.vue';
import CheckSquare from '../CheckSquare.vue';
import {
  enumFromSchema,
  formatDisplayText,
  inputTypeFromSchema,
  titleFromSchema,
} from './parse';
import { RequestJSONSchema, WidgetType } from './types';
import { intersection } from 'lodash';

// interface Props {
//   name: string;
//   field: RequestJSONSchema;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   initialValue: any;
//   renderLabel: boolean;
//   small: boolean;
//   required: boolean;
//   errors: // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   ErrorObject<string, Record<string, any>, unknown>[] | null | undefined;
//   overrideReadonly: boolean;
//   forceReadOnly: boolean;
// }

export default defineComponent({
  components: {
    SimpleArrayInput,
    CheckSquare,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    field: {
      type: Object as PropType<RequestJSONSchema>,
      required: true,
    },
    path: { type: String, required: true },
    initialValue: {
      type: [String, Number, Boolean, Array],
      required: false,
    },
    renderLabel: Boolean,
    small: Boolean,
    required: Boolean,
    errors: {
      type: Object as PropType<OutputUnit>,
      required: false,
    },
    overrideReadonly: Boolean,
    forceReadOnly: {
      type: Boolean,
      default: false,
    },
    raw: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectVal: Ref<string | null> = ref(null);
    const textInput = ref('');
    const numericInput = ref(props.field.minimum || 0);
    const booleanInput = ref(false);
    const fileInput = ref('');
    const dateInput = ref('');
    const fileDragging = ref(false);
    const fileName = computed(() => {
      if (fileInput.value) {
        return fileInput.value;
      }
      if (props.small) {
        return 'Choose a file...';
      }
      return 'Choose or drag/drop a file...';
    });

    function updateRaw() {
      try {
        const raw = JSON.parse(textInput.value);
        valueChanged(raw);
      } catch (_) {
        valueChanged(textInput.value);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let anyVal: any;

    const inputType = computed(() => {
      return inputTypeFromSchema(props.field);
    });

    const label = computed(() => {
      return titleFromSchema(props.field);
    });

    const formattedText = computed(() => {
      return formatDisplayText(props.field, textInput.value);
    });

    const enumVals = computed(() => {
      if (props.field.format === 'equipment-reference') {
        const assets = OpsController.Instance.getAssets(
          OpsController.Instance.selectedLabId
        );
        if (
          props.field['equipment-reference:asset_definition_ids'].length !== 0
        ) {
          const whitelistedAssetDefs =
            props.field['equipment-reference:asset_definition_ids'];

          const assetDefsInLab = assets.map((asset) => asset.definitionId);
          const matchingAssetDefs = intersection(
            whitelistedAssetDefs,
            assetDefsInLab
          );
          return assets.filter((asset) =>
            matchingAssetDefs.includes(asset.definitionId)
          );
        } else {
          return assets;
        }
      }
      return enumFromSchema(props.field);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function valueChanged(val: any | null) {
      emit('update:value', val);
      anyVal = val;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function populateInitialData(val: any) {
      if (props.raw) {
        textInput.value = JSON.stringify(val);
      } else if (
        inputType.value === WidgetType.TEXT ||
        inputType.value === WidgetType.PASSWORD ||
        props.field.readOnly ||
        props.forceReadOnly
      ) {
        textInput.value = isString(val) ? val : val?.toString();
      } else if (inputType.value === WidgetType.NUMERIC) {
        numericInput.value = val as unknown as number;
      } else if (inputType.value === WidgetType.SELECT) {
        selectVal.value = val as unknown as string;
      } else if (props.field.type === 'boolean') {
        booleanInput.value = val as unknown as boolean;
      } else if (
        inputType.value === WidgetType.DATE ||
        inputType.value === WidgetType.DATE_TIME
      ) {
        dateInput.value = val as unknown as string;
      }
      valueChanged(val);
    }

    onMounted(() => {
      anyVal = props.initialValue;
      populateInitialData(props.initialValue);
    });

    watch(
      () => props.initialValue,
      (val) => {
        anyVal = val;
        populateInitialData(val);
      }
    );

    watch(
      () => props.field,
      (newField, oldField) => {
        // if the schema changed, then clear out the value
        if (oldField && !isEqual(oldField, newField)) {
          populateInitialData(null);
        }
      },
      { deep: true }
    );

    watch(
      () => props.forceReadOnly,
      () => {
        populateInitialData(anyVal);
      }
    );

    function readFile(file: File) {
      const reader = new FileReader();
      reader.onload = () => {
        fileInput.value = file.name;
        emit('update:value', reader.result);
      };
      reader.readAsDataURL(file);
    }

    function fileSelected(event: Event) {
      const fileEvent = event.target as HTMLInputElement;
      if (fileEvent?.files?.length !== 1) {
        return;
      }

      const file = fileEvent.files[0];
      readFile(file);
    }

    const myError = computed(() => {
      if (props.errors?.errors?.length) {
        for (let i = 0; i < props.errors.errors.length; i += 1) {
          let errorFieldName = '';
          const error = props.errors.errors[i];
          if (error?.instanceLocation) {
            errorFieldName = error.instanceLocation;
          }
          if (errorFieldName.includes(props.path)) {
            return error;
          }
        }
      }
      return null;
    });

    const inError = computed(() => {
      return !!myError.value;
    });

    const errorMessage = computed(() => {
      if (myError.value) {
        // return myError.value.message;
        return 'Invalid value';
      }
      return '';
    });

    return {
      label,
      selectVal,
      textInput,
      numericInput,
      booleanInput,
      fileInput,
      fileName,
      dateInput,
      formattedText,
      enumVals,
      arraySubType: computed(() => {
        // @ts-ignore
        return props.field?.items?.type;
      }),
      numericMin: computed(() => {
        return props.field.minimum || -Infinity;
      }),
      numericMax: computed(() => {
        return props.field.maximum || Infinity;
      }),
      numericStep: computed(() => {
        return 1; // TODO: does json-schema not have interval/step
      }),
      inputType,
      WidgetType,
      updateRaw,
      valueChanged,
      fileSelected,
      fileDragEnter(e) {
        e.preventDefault();
        fileDragging.value = true;
      },
      fileDragOver(e) {
        e.preventDefault();
        fileDragging.value = true;
      },
      fileDragExit() {
        fileDragging.value = false;
      },
      fileDrop(e) {
        e.preventDefault();
        fileDragging.value = false;
        if (e.dataTransfer?.files.length) {
          readFile(e.dataTransfer.files[0]);
        }
      },
      inError,
      errorMessage,
      fileDragging,
    };
  },
});
