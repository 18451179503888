import {
  Common,
  nullCommon,
  Timelog,
  nullTimelog,
  IO,
  DefinedFrom,
  nullDefinedFrom,
  SimplifiedState,
} from '../model';

export enum ActionType {
  UNKNOWN = 'ACTION_TYPE_UNKNOWN',
  ASSISTANT = 'ACTION_TYPE_ASSISTANT',
  ASSISTANT_STEP = 'ACTION_TYPE_ASSISTANT_STEP',
  ASSISTANT_TASK = 'ACTION_TYPE_ASSISTANT_TASK',
  SUBSTRATE = 'ACTION_TYPE_SUBSTRATE',
  EXPRESSION = 'ACTION_TYPE_EXPRESSION',
  CLOUD = 'ACTION_TYPE_CLOUD',
  WORKFLOW = 'ACTION_TYPE_WORKFLOW',
  DIRECT = 'ACTION_TYPE_DIRECT',
  OTHER = 'ACTION_TYPE_OTHER',
  RECURRING = 'ACTION_TYPE_RECURRING',
  ERROR_HANDLER = 'ACTION_TYPE_ERROR_HANDLER',
  ADAPTER = 'ACTION_TYPE_ADAPTER',
  DOCUMENT_WORKFLOW = 'ACTION_TYPE_DOCUMENT_WORKFLOW',
  DIALOG = 'ACTION_TYPE_DIALOG',
}

export const TimelineActionTypeFilters = [
  ActionType.ASSISTANT,
  ActionType.ASSISTANT_STEP,
  ActionType.ASSISTANT_TASK,
  ActionType.DIALOG,
  ActionType.ADAPTER,
  ActionType.UNKNOWN,
];

export const AssistantTimelineActionTypeFilters = [
  ActionType.ASSISTANT,
  ActionType.ASSISTANT_STEP,
  ActionType.ASSISTANT_TASK,
];

interface SubactionPath {
  fromId: string;
  toId: string;
}

export interface JobCreation {
  fromFile: boolean;
  interactive: boolean;
  quick: boolean;
  recurring: boolean;
  standalone: boolean;
  programmaticallySchedulable: boolean;
}

export interface Constraints {
  labId: string;
  subactionPaths: SubactionPath[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actor: any;
  creation: JobCreation;
}

export interface DocumentWorkflow {
  generatedWorkflowId: string;
}

export interface WorkflowTreeNode {
  id: string;
  index: number;
  type: 'action' | 'loop' | 'branch';
  parent_id: string;
  children: WorkflowTreeNode[];
  label?: string;
  action_id?: string;
}

export interface Assistant {
  entrypoint: boolean;
  compliant?: boolean;
  hideStep?: boolean;
}

export interface Action {
  id: string;
  name: string;
  description: string;
  parentId: string;
  jobId?: string;
  state: SimplifiedState;
  assistant?: Assistant;
  constraint?: Constraints;
  inputs?: IO[];
  outputs?: IO[];
  timelog?: Timelog;
  completedBy?: string;
  definedFrom?: DefinedFrom;
  documentWorkflow?: DocumentWorkflow;
  children?: Action[];
  common: Common;
  schema?: string;
  parameterValues?: string;
  actionType?: ActionType;
  actorId?: string;
  workflowTree?: WorkflowTreeNode[];
}

export function nullAction(): Action {
  return {
    id: '',
    name: '',
    description: '',
    parentId: '',
    jobId: '',
    actionType: ActionType.UNKNOWN,
    state: SimplifiedState.UNKNOWN,
    inputs: [],
    outputs: [],
    timelog: nullTimelog(),
    definedFrom: nullDefinedFrom(),
    children: [],
    common: nullCommon(),
  };
}
